import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import( '../views/home/Home')
const LoGin = () => import( '../views/register/Login')
const InFo = () => import( '../views/info/InFo')
const List = () => import( '../views/list/List')
const ShopCart = () => import( '../views/shopcart/ShopCart')
const Register = () => import( '../views/register/Register')
const Order = () => import( '../views/order/Order')
const PersonalCenter = () => import( '../views/personal/PersonalCenter')
const ChildOrder = () => import( '../views/personal/personalchild/ChildOrder')
const ChildComment = () => import( '../views/personal/personalchild/ChildComment')
const ChildCenter = () => import( '../views/personal/personalchild/ChildCenter')
const ChildAddress = () => import( '../views/personal/personalchild/ChildAddress')
const ChildInfo = () => import( '../views/personal/personalchild/ChildInfo')
const ChildPwd = () => import( '../views/personal/personalchild/ChildPwd')
const addAddress = () => import( '../views/order/orderchild/addAddress')
const SearchResult = () => import( '../views/searchresult/SearchResult')






const routes = [
  {
    path: '',
    name: 'DefaultHome',
    component: Home,
    meta: {
      keepAlive: true, // 组件需要缓存
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true, // 组件需要缓存
    }
  },
  {
    path: '/addaddress',
    name: 'addddress',
    component: addAddress
  },
  {
    path: '/login',
    name: 'Login',
    component:LoGin,
    meta:{
      title:'登陆'
    }
  },
  {
    path: '/info',
    name: 'InFo',
    component:InFo,
  },
  {
    path: '/list',
    name: 'List',
    component:List,
    meta:{
      title:'图书列表'
    }
  },
  {
    path: '/order',
    name: 'Order',
    component:Order,
    meta:{
      title:'订单'
    }
  },
  {
    path: '/shopcart',
    name: 'ShopCart',
    component:ShopCart,
    meta:{
      title:'图书列表-购物车'
    }
  },
  {
    path: '/searchresult',
    name: 'SearchResult',
    component:SearchResult,
    meta:{
      title:''
    }
  },
  {
    path: '/register',
    name: 'Register',
    component:Register,
    meta:{
      title:'注册'
    }
  },
  {
    path: '/personalcenter',
    name: 'PersonalCenter',
    redirect:'personalcenter/childorder',
    component:PersonalCenter,
    children:[
      {
        path:'/personalcenter/childorder',
        name: 'ChildOrder',
        component:ChildOrder,
      },
      {
        path:'childcomment',
        name:'ChildComment',
        component:ChildComment,
      },
      {
        path:'childcenter',
        name:'ChildCenter',
        component:ChildCenter,
      },
      {
        path:'childaddress',
        name:'ChildAddress',
        component:ChildAddress,
      },
      {
        path:'childinfo',
        name:'ChildInfo',
        component:ChildInfo,
      },
      {
        path:'childpwd',
        name:'ChildPwd',
        component:ChildPwd,
      },
    ],
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
