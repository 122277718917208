import { createStore } from 'vuex'

import getters from "@/store/getters";
import mutations from "@/store/mutations";
import actions from "@/store/actions";
const state = {
  user:{
    isLogin:window.localStorage.getItem('token') ? true : false
  },
  cartCount:0
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
})
