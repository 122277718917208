import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import route from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'vant/lib/index.css';
import { Swipe, SwipeItem } from 'vant';

createApp(App).use(ElementPlus)
    .use(Swipe).use(SwipeItem)
    .use(store).use(router).use(route).mount('#app')


